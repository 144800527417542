<style>
  .ticket{
    padding-bottom: 5px;
  }
</style>
<template>
  <div id="footer-bar" class="footer-bar-6">
    <router-link :to="{ name: 'TicketHome' }" active-class="active-nav"
      ><i class="fa fa-home"></i><span>Home</span></router-link
    >
    <router-link :to="{ name: 'Explorer' }" active-class="active-nav"
      ><img src="/img/shop.png" width="24" class="ticket"><span>Shop</span></router-link
    >
    <router-link
      :to="{ name: 'YourTicket' }"

      ><img src="/img/qrcode.png" width="18" class="ticket"><span>Ticket</span><strong></strong
    ></router-link>
    <router-link :to="{ name: 'ContentVideo' }" active-class="active-nav"
      ><i class="fa fa-play"></i><span>Content</span></router-link
    >
    <router-link :to="{ name: 'AuthUserProfile' }" active-class="active-nav"
      ><i class="fa fa-user"></i><span>Profile</span></router-link
    >
  </div>
</template>

<script>
import axios from "axios";
import { menuOpen } from "@/appkit.js";
export default {
  name: "Footer",
  props: {},
  emits: ["reload"],
  methods: {
    menuOpen,
    signOut() {
      axios.defaults.headers.common["Authorization"] = "";
      this.$store.commit("removeUserInformation");
    },
  },
};
</script>
